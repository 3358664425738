import { useEffect, useState } from 'react';
import './App.css';
import Footer from './components/common/Footer';
import Cat from './components/sections/Cat';
import Cat2 from './components/sections/Cat2';
import Feature from './components/sections/Feature';
import Feature2 from './components/sections/Feature2';
import Hero from './components/sections/Hero';
import Spinner from './components/common/Spinner';
import Stats from './components/sections/Stats';
import './index.css';
import ModalVideo from './components/feature/ModalVideo';

const App = () => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [settings, setSettings] = useState([]);
  const [open, setOpen] = useState(false);
  
  const fetchData = () => {
    let url = 'https://api.sheety.co/7b9b9ddd557c0acab06166f990ab31f1/tnbtApiRest/landing';
      fetch(url)
      .then((response) => response.json())
      .then(json => {
        setData(json.landing);
        setLoading(false);
      });
  };
  
  const fetchSettings = () => {
    let url = 'https://api.sheety.co/7b9b9ddd557c0acab06166f990ab31f1/tnbtApiRest/settings';
      fetch(url)
      .then((response) => response.json())
      .then(json => {
        setSettings(json.settings);
        setLoading(false);
      });
  };

  useEffect(fetchData, []);
  useEffect(fetchSettings, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-gray-900">
      <main>
        <Hero data={data} settings={settings} setOpen={setOpen} />
        <Cat data={data} settings={settings} setOpen={setOpen} />
        <Feature data={data} settings={settings} setOpen={setOpen} />
        <Feature2 data={data} settings={settings} setOpen={setOpen}/>
        <Stats data={data} settings={settings} setOpen={setOpen} />
        <Cat2 data={data} settings={settings} setOpen={setOpen} />
      </main>
      <Footer settings={settings}/>
      <ModalVideo 
        open={open}
        setOpen={setOpen}
      />
    </div>
  )
}

export default App;