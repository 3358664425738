// import {
//   ArrowPathIcon,
//   CloudArrowUpIcon,
//   Cog6ToothIcon,
//   FingerPrintIcon,
//   LockClosedIcon,
//   ServerIcon,
// } from '@heroicons/react/20/solid';
import secondaryImage from "../../assets/secondaryImage.jpeg"

const Feature2 = ({ data }) => {
  
  // const secondaryFeatures = [
  //   {
  //     name: data?.[27]?.value,
  //     description: data?.[27]?.description,
  //     icon: CloudArrowUpIcon,
  //   },
  //   {
  //     name: data?.[28]?.value,
  //     description: data?.[28]?.description,
  //     icon: LockClosedIcon,
  //   },
  //   {
  //     name: data?.[29]?.value,
  //     description: data?.[29]?.description,
  //     icon: ArrowPathIcon,
  //   },
  //   {
  //     name: data?.[30]?.value,
  //     description: data?.[30]?.description,
  //     icon: FingerPrintIcon,
  //   },
  //   {
  //     name: data?.[31]?.value,
  //     description: data?.[31]?.description,
  //     icon: Cog6ToothIcon,
  //   },
  //   {
  //     name: data?.[32]?.value,
  //     description: data?.[32]?.description,
  //     icon: ServerIcon,
  //   },
  // ];
  return (
    <div className="mt-32 sm:mt-56">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">{data?.[23]?.value}</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">{data?.[24]?.value}</p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            {data?.[25]?.value}
          </p>
        </div>
      </div>
      <div className="relative overflow-hidden pt-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <img
            src={secondaryImage}
            alt={data?.[26]?.description}
            className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-white/10"
            width={2432}
            height={1442}
          />
          <div className="relative" aria-hidden="true">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-900 pt-[7%]" />
          </div>
        </div>
      </div>
      {/* <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          {secondaryFeatures.map((feature) => (
            <div key={`f-${feature.name}`} className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                {feature.name}
              </dt>{' '}
              <dd className="inline">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div> */}
    </div>
  );
}; 

export default Feature2;